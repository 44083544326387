import React from 'react';
import ErrorPage from 'app/components/misc/ErrorPage';

class Error extends React.Component {
  static async getInitialProps(context) {
    const { res, err } = context;
    let statusCode = null;
    if (res) {
      statusCode = res.statusCode;
    } else if (err) {
      statusCode = err.statusCode;
    }

    return { statusCode };
  }

  render() {
    return <ErrorPage statusCode={this.props.statusCode} />;
  }
}

export default Error;
